<template>
  <v-navigation-drawer absolute permanent>
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <v-avatar color="primary" size="36">
            <span class="white--text text-h5">{{ userName.substr(0, 2) }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ userName }}</v-list-item-title>
          <v-list-item-subtitle>{{ rut }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group color="primary">
        <v-list-item v-for="(item, i) in items" :to="item.href" :key="i">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-divider> </v-divider>

    <v-list dense>
      <v-list-item
        v-for="logout in logouts"
        @click="exitSession"
        :key="logout.title"
      >
        <v-list-item-icon>
          <v-icon>{{ logout.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ logout.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>
<script>
import auth from "@/logic/auth";
import axios from "axios";
export default {
  name: "AppBar",
  components: {},

  computed: {
    show() {
      console.log("entra a mostrar menu");
      return auth.getUserName() != undefined ? true : false;
    },
    userName() {
      console.log("entra al computado");
      return auth.getUserName();
    },
    rol() {
      return auth.getUserType();
    },
    rut() {
      return auth.getUserRut();
    },
  },
  data() {
    return {
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
      items: [],
      logouts: [{ title: "Salir", icon: "mdi-logout", href: "/logout" }],
      dialog: false,
      showMenu: auth.getUserRut() != "" ? true : false,
      submenus: [
        { title: "Boleta Electrónica", link: "/boleta-electronica" },
        { title: "Facturación Electrónica", link: "/facturacion-electronica" },
        { title: "Firma Electronica", link: "/firma-electronica" },
        { title: "Hosting", link: "/hosting" },
      ],
    };
  },
  mounted() {
    this.getIdUserByRut();
  },
  methods: {
    logout() {
      console.log("entra a lgout");

      console.log("imprime credenciales");
      console.log(this.email);
      console.log(this.password);
      axios.post("/logout");
    },
    getItems() {
      console.log("entra");
      if (auth.getUserType() === "admin") {
        let items = [
        { title: "Dashboard", icon: "mdi-view-dashboard", href: "/admin" },
          { title: "Deudas", icon: "mdi-cash", href: "/deudas" },
          { title: "Pagos", icon: "mdi-credit-card-outline", href: "/pagos" },
          { title: "Empresas", icon: "mdi-store", href: "/clientes" },

          { title: "Contratos", icon: "mdi-file-document", href: "/contratos" },
          {
            title: "Días de Facturación",
            icon: "mdi-calendar-month-outline",
            href: "/periodos-facturacion",
          },
          {
            title: "Equipos",
            icon: "mdi-desktop-classic",
            href: "/listar-equipos",
          },
          /*  {
            title: "Servicios",
            icon: "mdi-format-list-bulleted-square",
            href: "/services",
          },*/
          { title: "Planes", icon: "mdi-text-box-outline", href: "/planes" },
        
        ];
        return items;
      }
      if (auth.getUserType() === "user") {
        console.log("imprime tipo usuario");
        console.log(auth.getUserType());
        console.log("imprime imprime rut");
        console.log(auth.getUserRut());
        let request = {
          rut: auth.getUserRut(),
        };
        console.log("imprime request");
        console.log(request);
        let items = [
          {
            title: "Mis Deudas",
            icon: "mdi-cash",
            href: "/my-debts/" + this.id_customer,
          },
          {
            title: "Mis Pagos",
            icon: "mdi-credit-card-outline",
            href: "/my-payments/" + this.id_customer,
          },
          {
            title: "Mis Contratos",
            icon: "mdi-file-document",
            href: "/mis-contratos/" + this.id_customer,
          },
        ];

        return items;
      }
    },
    exitSession() {
      auth.exitSession();
    },
    getIdUserByRut() {
      let request = {
        rut: this.rut,
      };
      axios.post("/customer-by-rut", request).then((response) => {
        console.log("imprime el id por el rut " + response.data.id);
        this.id_customer = response.data.id;
        console.log("imprime el id custmoer por el rut " + this.id_customer);
        this.items = this.getItems();
      });
    },
  },
};
</script>